import { useEffect, useState } from 'react';
import v1_desktop from '../assets/desktop/bad/v1.png';
import v2_desktop from '../assets/desktop/bad/v2.png';
import v3_desktop from '../assets/desktop/bad/v3.png';
import v4_desktop from '../assets/desktop/bad/v4.png';
import v5_desktop from '../assets/desktop/bad/v5.png';
import p1_desktop from '../assets/desktop/good/p1.png';
import p2_desktop from '../assets/desktop/good/p2.png';
import p3_desktop from '../assets/desktop/good/p3.png';
import p4_desktop from '../assets/desktop/good/p4.png';
import p5_desktop from '../assets/desktop/good/p5.png';
import girl_desktop from '../assets/desktop/character/girl.png';
import logo_desktop from '../assets/desktop/misc/logo.png';
import GameEngine from './engine';
import { useDispatch, useSelector } from 'react-redux';
import { incrementByAmount, resetPoints, updatePointsMaxAction } from 'app/slices/pointsSlice';
import { finishScreen, increaseNumberOfTries, intermediateScreen } from 'app/slices/gameSlice';
import keyboardKeys from 'shared/constants/keyboardKeys';

const goodPictures = [p1_desktop, p2_desktop, p3_desktop, p4_desktop, p5_desktop];
const badPictures = [v1_desktop, v2_desktop, v3_desktop, v4_desktop, v5_desktop];

const pictures = {
  girl: girl_desktop,
  logo: logo_desktop,
};

function Game() {
  const dispatch = useDispatch();

  const [rightPressed, setRightPressed] = useState(false);
  const [leftPressed, setLeftPressed] = useState(false);

  const points = useSelector((state) => (state as any).points.currentValue);
  const scale = useSelector((state) => (state as any).gameProperties.scale);
  const numberOfTries = useSelector((state) => (state as any).game.numberOfTries);
  const incrementNumberOfTries = () => dispatch(increaseNumberOfTries());
  const incrementPointsByAmount = (amount: number) => dispatch(incrementByAmount(amount));
  const updatePointsMax = () => dispatch(updatePointsMaxAction());
  const nextScreen = () => {
    if (numberOfTries === 2) dispatch(finishScreen());
    else dispatch(intermediateScreen());
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (keyboardKeys.right.includes(e.key)) {
      setRightPressed(true);
    } else if (keyboardKeys.left.includes(e.key)) {
      setLeftPressed(true);
    }
  };

  const keyUpHandler = (e: KeyboardEvent) => {
    if (keyboardKeys.right.includes(e.key)) {
      setRightPressed(false);
    } else if (keyboardKeys.left.includes(e.key)) {
      setLeftPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    document.addEventListener('keyup', keyUpHandler, false);
    dispatch(resetPoints());
  }, []);

  return (
    <>
      <GameEngine
        canvas={{
          h: 694,
          w: 1290,
        }}
        basket={{
          h: 61,
          w: 209,
        }}
        girl={{
          h: 369,
          w: 267,
        }}
        logo={{
          h: 107,
          w: 160,
        }}
        dimensions={80}
        fallingFieldPadding={114}
        movingFieldPadding={59}
        minute={60000}
        dTime={10}
        goodPictures={goodPictures}
        badPictures={badPictures}
        rightPressed={rightPressed}
        setRightPressed={setRightPressed}
        leftPressed={leftPressed}
        setLeftPressed={setLeftPressed}
        pictures={pictures}
        scale={scale}
        points={points}
        updatePointsMax={updatePointsMax}
        incrementPointsByAmount={incrementPointsByAmount}
        incrementNumberOfTries={incrementNumberOfTries}
        nextScreen={nextScreen}
      />
    </>
  );
}

export default Game;

import st from '../../screens.module.scss';
import girl from 'shared/assets/desktop/character/success_girl.png';

const messages = {
  success: 'Улетело, проверяйте!',
  usedEmail: 'Вы здорово сыграли в этот раз! Но мы уже дарили вам подарок, ищите на почте 🙂',
  error: 'Ошибка сервера.',
};

const ResponsePage = ({ variant }: { variant: 'success' | 'usedEmail' | 'error' }) => {
  return (
    <div className={st.wrapper_small}>
      <img src={girl} alt="Девушка держит бутылку воды" />
      <p className={st.text_big} style={{ marginBottom: 0, marginTop: '23px' }}>
        {messages[variant]}
      </p>
    </div>
  );
};

export default ResponsePage;

import { useSelector } from 'react-redux';
import BackGround from 'shared/ui/BackGround/BackGround';
import { Modal } from 'shared/ui/Modal/Modal';
import { useState } from 'react';
import NotificationPage from './pages/NotificationPage';
import FormPage from './pages/FormPage';
import ResponsePage from './pages/ResponsePage';

export type TPages = 'notification' | 'form' | 'success' | 'usedEmail' | 'error';

const FinishScreen = () => {
  const [page, setPage] = useState<TPages>('notification');
  const points: number = useSelector((state) => (state as any).points.value);

  const pages = {
    notification: <NotificationPage setPage={setPage} points={points} />,
    form: <FormPage setPage={setPage} points={points} />,
    success: <ResponsePage variant="success" />,
    usedEmail: <ResponsePage variant="usedEmail" />,
    error: <ResponsePage variant="error" />,
  };

  return (
    <>
      <Modal>{pages[page]}</Modal>
      <BackGround />
    </>
  );
};

export default FinishScreen;

const checkIsCatch = (
  xPos: number,
  yPos: number,
  dy: number,
  dimensions: number,
  height: number,
  paddleHeight: number,
  paddleWidth: number,
  paddleX: number
) =>
  yPos + dy + dimensions > height - paddleHeight &&
  yPos + dy + dimensions <= height - paddleHeight + dy &&
  ((xPos > paddleX && xPos < paddleX + paddleWidth) ||
    (xPos + dimensions > paddleX && xPos + dimensions < paddleX + paddleWidth));

const checkIsFallen = (yPos: number, dy: number, height: number) => yPos + dy > height;

export { checkIsCatch, checkIsFallen };

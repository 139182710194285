import { finishScreen, gameScreen } from 'app/slices/gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import BackGround from 'shared/ui/BackGround/BackGround';
import { Button } from 'shared/ui/Button/Button';
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import { getReward } from 'shared/constants/rewards';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';

const IntermediateScreen = () => {
  const dispatch = useDispatch();
  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showGameWinning',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const points: number = useSelector((state) => (state as any).points.value);

  const onRetry = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGameRepeat',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameScreen());
  };

  const onTake = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGamePrize',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(finishScreen());
  };

  if (points > 100) {
    return (
      <>
        <Modal>
          <div className={st.wrapper}>
            <h2 className={st.header}>{points} баллов</h2>
            <p className={st.text}>А вы знаете толк в полезных для фитнес-зала вещах!</p>
            <p className={st.text_big}>Держите приз: {getReward(points)}.</p>
            <div className={st.buttons_wrapper}>
              <Button label={'Попробовать снова'} onClick={onRetry} />
              <Button label={'Забрать подарок'} onClick={onTake} />
            </div>
          </div>
        </Modal>
        <BackGround />
      </>
    );
  }

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h2 className={st.header}>Есть риск, что Ксюша будет выглядеть нелепо на тренировке.</h2>
          <p className={st.text}>Попробуйте ещё раз или заберите гарантированный приз.</p>
          <div className={st.buttons_wrapper}>
            <Button label={'Попробовать снова'} onClick={onRetry} />
            <Button label={'Забрать подарок'} onClick={onTake} />
          </div>
        </div>
      </Modal>
      <BackGround />
    </>
  );
};

export default IntermediateScreen;

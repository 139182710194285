import { useDispatch } from 'react-redux';
import { gameScreen } from 'app/slices/gameSlice';
import BackGround from 'shared/ui/BackGround/BackGround';
import { Button } from 'shared/ui/Button/Button';
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';

const StartupScreen = () => {
  const dispatch = useDispatch();
  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showGameRules',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const onStart = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGameStart',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameScreen());
  };

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h2 className={st.header}>Правила игры</h2>
          <p className={st.text}>
            Ваша задача помочь Ксюше ловить вещи, которые подойдут для похода в зал, и уворачиваться
            от того, что нужно оставить дома.
          </p>
          <p className={st.text}>
            <b>Управление:</b> на компьютере — стрелками клавиатуры, на телефоне — нажатие на левую
            и правую части экрана.
          </p>
          <p className={st.text}>
            Игра длится ровно <b>2 минуты</b>, чем больше спортивного инвентаря поймает Ксюша, тем
            больше баллов вы заработаете, тем ценнее подарок от Spirit:
          </p>
          <table className={st.table}>
            <tr>
              <td className={st.text_bold_red}>+5 баллов</td>
              <td className={st.text_bold_purple}>–10 баллов</td>
            </tr>
            <tr>
              <td>За каждую спортивную вещь</td>
              <td>За каждую ненужную для тренировки вещь</td>
            </tr>
          </table>
          <p className={st.text_bold} style={{ marginBottom: 0 }}>
            3 попытки, засчитывается лучшая
          </p>
          <div className={st.buttons_wrapper}>
            <Button label={'Старт игры'} onClick={onStart} />
          </div>
          <p className={st.text} style={{ marginTop: '23px' }}>
            Промокод можно применить только для покупки первого&nbsp;абонемента
          </p>
          <a
            href="https://docs.google.com/document/d/198UFvzfa0HRl8KPH0e4w3OpKaniG5tpb/edit"
            className={st.text_link}
            target="_blank"
            rel="noreferrer"
          >
            Подробные правила
          </a>
        </div>
      </Modal>
      <BackGround />
    </>
  );
};

export default StartupScreen;

import { createSlice } from '@reduxjs/toolkit';

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    screen: 'start',
    numberOfTries: 0,
  },
  reducers: {
    gameScreen: (state) => {
      state.screen = 'game';
    },
    intermediateScreen: (state) => {
      state.screen = 'intermediate';
    },
    finishScreen: (state) => {
      state.screen = 'finish';
    },
    increaseNumberOfTries: (state) => {
      state.numberOfTries += 1;
    },
  },
});

export const { gameScreen, intermediateScreen, finishScreen, increaseNumberOfTries } =
  gameSlice.actions;

export default gameSlice.reducer;

type TRewards = {
  [grade: number]: string;
};

const rewards: TRewards = {
  1: 'промокод на скидку от партнера',
  2: 'промокод на скидку 10% на абонемент Spirit. Fitness',
  3: 'промокод на скидку 15% на абонемент Spirit. Fitness',
  4: 'промокод на скидку 20% на абонемент Spirit. Fitness',
};

const getGrade = (p: number) => {
  if (p < 100) return 1;
  if (p >= 100 && p < 125) return 2;
  if (p >= 125 && p < 150) return 3;
  return 4;
};

const getReward = (p: number) => rewards[getGrade(p)];

export { getReward, getGrade, rewards, type TRewards };

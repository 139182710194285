import { useForm, SubmitHandler } from 'react-hook-form';
import st from '../../screens.module.scss';
import { TPages } from '..';
import { Button } from 'shared/ui/Button/Button';
import { getGrade, getReward } from 'shared/constants/rewards';
import { useState } from 'react';
import { InputText } from 'shared/ui/InputText/InputText';
import { Checkbox } from 'shared/ui/Checkbox/Checkbox';
import { postPlayer } from 'shared/api/players';
import { dataLayerPush } from 'shared/hooks/useDataLayerPush';

type Inputs = {
  name: string;
  email: string;
  personalData: boolean;
  adds: boolean;
};

type Props = {
  points: number;
  setPage: React.Dispatch<React.SetStateAction<TPages>>;
};

const FormPage = ({ points, setPage }: Props) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'sendFormGame',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    setSubmitDisabled(true);
    postPlayer({
      name: data.name,
      email: data.email,
      points,
      grade: getGrade(points),
    }).then((res) => setPage(res.status as 'success' | 'usedEmail' | 'error'));
  };

  const header =
    points > 100 ? (
      <>
        <h2 className={st.header}>Ваш подарок — {getReward(points)}.</h2>
        <p className={st.text}>Куда прислать промокод?</p>
      </>
    ) : (
      <>
        <h2 className={st.header}>Не расстраивайся, мы уже отправили тебе приятный приз.</h2>
        <p className={st.text}>Куда его прислать?</p>
      </>
    );

  return (
    <div className={st.wrapper}>
      {header}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={st.buttons_wrapper}>
          <InputText
            placeholder="Имя"
            error={errors.name}
            rest={register('name', { required: true, maxLength: 50 })}
          />
          <InputText
            placeholder="example@example.ru"
            error={errors.email}
            type="email"
            rest={register('email', {
              required: true,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              maxLength: 50,
            })}
          />
          <div className={st.checkbox_wrapper}>
            <Checkbox
              id="personalData"
              label="Я соглашаюсь на обработку персональных данных"
              error={!!errors.personalData}
              rest={register('personalData', { required: true })}
            />
            <Checkbox
              id="adds"
              label="Я соглашаюсь на получение рекламных коммуникаций"
              error={!!errors.adds}
              rest={register('adds', { required: true })}
            />
          </div>
          <Button type="submit" label="Жду" disabled={submitDisabled} />
        </div>
      </form>
    </div>
  );
};

export default FormPage;

import { configureStore } from '@reduxjs/toolkit';
import pointsSlice from './slices/pointsSlice';
import gameSlice from './slices/gameSlice';
import gamePropertiesSlice from './slices/gamePropertiesSlice';

export default configureStore({
  reducer: {
    points: pointsSlice,
    game: gameSlice,
    gameProperties: gamePropertiesSlice,
  },
});

import getRandomInt from './getRandomInt';
import getRandomIntFromTwoIntervals from './getRandomIntFromTwoIntervals';

const getRandomIntExceptInterval = (min: number, max: number, except: [number, number]) => {
  if (min < except[0] && max > except[1])
    return getRandomIntFromTwoIntervals([min, except[0]], [max, except[1]]);

  if (min > except[0] && max > except[1]) return getRandomInt(except[1], max);

  if (min < except[0] && max < except[1]) return getRandomInt(min, except[0]);

  return getRandomInt(min, max);
};

export default getRandomIntExceptInterval;

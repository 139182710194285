import { createSlice } from '@reduxjs/toolkit';

export const pointsSlice = createSlice({
  name: 'points',
  initialState: {
    value: 0,
    currentValue: 0,
  },
  reducers: {
    incrementByAmount: (state, action) => {
      if (state.currentValue + action.payload < 0) state.currentValue = 0;
      else state.currentValue += action.payload;
    },
    resetPoints: (state) => {
      state.currentValue = 0;
    },
    updatePointsMaxAction: (state) => {
      if (state.currentValue > state.value) state.value = state.currentValue;
    },
  },
});

export const { incrementByAmount, resetPoints, updatePointsMaxAction } = pointsSlice.actions;

export default pointsSlice.reducer;

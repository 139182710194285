import { useState } from 'react';
import { Stage, Layer, Rect, Text } from 'react-konva';
import { useInterval } from 'shared/hooks/useInterval';
import getRandomInt from 'shared/tools/getRandomInt';
import useImage from 'use-image';
import millisecondsToMinutesAndSeconds from 'shared/tools/millisecondsToMinutesAndSeconds';
import { checkIsCatch, checkIsFallen } from './helpers';
import st from './index.module.scss';
import { TSizeEntity } from './types';
import getRandomIntExceptInterval from 'shared/tools/getRandomIntExceptInterval';

type Props = {
  canvas: TSizeEntity;
  basket: TSizeEntity;
  girl: TSizeEntity;
  logo: TSizeEntity;
  dimensions: number;
  fallingFieldPadding: number;
  movingFieldPadding: number;
  minute: number;
  dTime: number;
  goodPictures: string[];
  badPictures: string[];
  rightPressed: boolean;
  setRightPressed: React.Dispatch<React.SetStateAction<boolean>>;
  leftPressed: boolean;
  setLeftPressed: React.Dispatch<React.SetStateAction<boolean>>;
  pictures: {
    girl: string;
    logo: string;
  };
  scale: number;
  points: number;
  updatePointsMax: () => void;
  incrementPointsByAmount: (n: number) => void;
  incrementNumberOfTries: () => void;
  nextScreen: () => void;
};

function GameEngine({
  canvas,
  basket,
  girl,
  logo,
  dimensions,
  fallingFieldPadding,
  movingFieldPadding,
  minute,
  dTime,
  goodPictures,
  badPictures,
  rightPressed,
  leftPressed,
  pictures,
  scale,
  setRightPressed,
  setLeftPressed,
  points,
  updatePointsMax,
  incrementPointsByAmount,
  incrementNumberOfTries,
  nextScreen,
}: Props) {
  const [dy, setDy] = useState(3);
  const [lastPointsGained, setLastPointsGained] = useState('');
  const [lastPointsGainedOpacity, setLastPointsGainedOpacity] = useState(0);
  const [timer, setTimer] = useState(1.5 * minute);

  const [yGood, setYGood] = useState(getRandomInt(-400, -100));
  const [xGood, setXGood] = useState(getRandomInt(fallingFieldPadding, canvas.w / 2 - dimensions));
  const [pictureGood, setPictureGood] = useState(goodPictures[0]);

  const [yBad, setYBad] = useState(getRandomInt(-400, -100));
  const [xBad, setXBad] = useState(
    getRandomInt(canvas.w / 2, canvas.w - dimensions - fallingFieldPadding)
  );
  const [pictureBad, setPictureBad] = useState(badPictures[0]);

  const [xPaddle, setXPaddle] = useState((canvas.w - basket.w) * 0.5);

  const drawGood = (isCatch: boolean, isFallen: boolean) => {
    if (isCatch) {
      setYGood(getRandomInt(-200, -100));
      setXGood(
        getRandomIntExceptInterval(
          fallingFieldPadding,
          canvas.w - dimensions - fallingFieldPadding,
          [xBad - fallingFieldPadding, xBad + dimensions + fallingFieldPadding]
        )
      );
      incrementPointsByAmount(5);
      const newPic = goodPictures[getRandomInt(0, 4)];
      setPictureGood(newPic);
      setLastPointsGained('+5');
      setLastPointsGainedOpacity(1);
    } else if (isFallen) {
      setYGood(getRandomInt(-200, -100));
      setXGood(
        getRandomIntExceptInterval(
          fallingFieldPadding,
          canvas.w - dimensions - fallingFieldPadding,
          [xBad - fallingFieldPadding, xBad + dimensions + fallingFieldPadding]
        )
      );
      const newPic = goodPictures[getRandomInt(0, 4)];
      setPictureGood(newPic);
      lastPointsGainedOpacity > 0 && setLastPointsGainedOpacity((prev) => prev - 0.005);
    } else {
      setYGood((prev) => prev + dy);
      lastPointsGainedOpacity > 0 && setLastPointsGainedOpacity((prev) => prev - 0.005);
    }
  };
  const drawBad = (isCatch: boolean, isFallen: boolean) => {
    if (isCatch) {
      setYBad(getRandomInt(-200, -100));
      setXBad(
        getRandomIntExceptInterval(
          fallingFieldPadding,
          canvas.w - dimensions - fallingFieldPadding,
          [xGood - fallingFieldPadding, xGood + dimensions + fallingFieldPadding]
        )
      );
      const newPic = badPictures[getRandomInt(0, 4)];
      setPictureBad(newPic);
      incrementPointsByAmount(-10);
      setLastPointsGained('-10');
      setLastPointsGainedOpacity(1);
    } else if (isFallen) {
      setYBad(getRandomInt(-200, -100));
      setXBad(
        getRandomIntExceptInterval(
          fallingFieldPadding,
          canvas.w - dimensions - fallingFieldPadding,
          [xGood - fallingFieldPadding, xGood + dimensions + fallingFieldPadding]
        )
      );
      const newPic = badPictures[getRandomInt(0, 4)];
      setPictureBad(newPic);
      lastPointsGainedOpacity > 0 && setLastPointsGainedOpacity((prev) => prev - 0.005);
    } else {
      setYBad((prev) => prev + dy);
      lastPointsGainedOpacity > 0 && setLastPointsGainedOpacity((prev) => prev - 0.005);
    }
  };

  function drawPaddle() {
    setXPaddle((prev) => {
      if (rightPressed && !leftPressed)
        return Math.min(prev + 7, canvas.w - basket.w - movingFieldPadding);
      if (leftPressed && !rightPressed) return Math.max(prev - 7, movingFieldPadding);
      return prev;
    });
  }

  const draw = () => {
    const goodIsCatch = checkIsCatch(
      xGood,
      yGood,
      dy,
      dimensions,
      canvas.h,
      basket.h,
      basket.w,
      xPaddle
    );
    const goodIsFallen = checkIsFallen(yGood, dy, canvas.h);
    const badIsCatch = checkIsCatch(
      xBad,
      yBad,
      dy,
      dimensions,
      canvas.h,
      basket.h,
      basket.w,
      xPaddle
    );
    const badIsFallen = checkIsFallen(yBad, dy, canvas.h);

    drawGood(goodIsCatch, goodIsFallen);
    drawBad(badIsCatch, badIsFallen);
    drawPaddle();

    if (timer === 0.5 * minute) setDy(5);
    setTimer((prev) => prev - dTime);
  };

  useInterval(
    draw,
    () => {
      incrementNumberOfTries();
      nextScreen();
      updatePointsMax();
    },
    dTime,
    [yGood],
    timer <= dTime
  );

  return (
    <div className={st.wrapper}>
      <Stage width={canvas.w * scale} height={canvas.h * scale} scaleX={scale} scaleY={scale}>
        <Layer>
          {/* character */}
          <Rect
            fillPatternImage={useImage(pictures.girl)[0]}
            x={xPaddle - Math.floor(0.5 * girl.w - 0.5 * basket.w)}
            y={canvas.h - girl.h}
            width={girl.w}
            height={girl.h}
          />
          <Text
            text={lastPointsGained}
            fontSize={40}
            fontFamily="Gotham Pro Bold"
            fill="#ffffff"
            align="center"
            width={basket.w}
            opacity={lastPointsGainedOpacity < 0 ? 0 : lastPointsGainedOpacity}
            x={xPaddle}
            y={canvas.h - basket.h + (basket.h - 40) * 0.5}
          />
        </Layer>
        <Layer>
          {/* good */}
          <Rect
            fillPatternImage={useImage(pictureGood)[0]}
            x={xGood}
            y={yGood}
            width={dimensions}
            height={dimensions}
          />
          {/* bad */}
          <Rect
            fillPatternImage={useImage(pictureBad)[0]}
            x={xBad}
            y={yBad}
            width={dimensions}
            height={dimensions}
          />
        </Layer>
        <Layer>
          {/* points */}
          <Text
            text={points.toString()}
            fontSize={40}
            fontFamily="Gotham Pro Bold"
            fill="#ffffff"
            align="right"
            width={200}
            y={30}
            x={canvas.w - 230}
          />
          {/* timer */}
          <Text
            text={millisecondsToMinutesAndSeconds(timer)}
            fontSize={40}
            fontFamily="Gotham Pro Bold"
            fill="#ffffff"
            align="center"
            width={100}
            y={30}
            x={canvas.w * 0.5 - 50}
          />
          {/* logo */}
          <Rect
            fillPatternImage={useImage(pictures.logo)[0]}
            x={30}
            y={30}
            width={logo.w}
            height={logo.h}
          />
        </Layer>
        <Layer>
          <Rect
            width={canvas.w / 2}
            height={canvas.h}
            x={0}
            y={0}
            onPointerDown={() => setLeftPressed(true)}
            onPointerUp={() => setLeftPressed(false)}
            onPointerCancel={() => setLeftPressed(false)}
            onPointerOut={() => setLeftPressed(false)}
          />
          <Rect
            width={canvas.w / 2}
            height={canvas.h}
            x={canvas.w / 2}
            y={0}
            onPointerDown={() => setRightPressed(true)}
            onPointerUp={() => setRightPressed(false)}
            onPointerCancel={() => setRightPressed(false)}
            onPointerOut={() => setRightPressed(false)}
          />
        </Layer>
      </Stage>
    </div>
  );
}

export default GameEngine;

import axios from 'axios';

type TProps = {
  name: string;
  email: string;
  points: number;
  grade: number;
};

export interface SuccessData {
  attributes: {
    createdAt: string;
    email: string;
    grade: number;
    name: string;
    points: number;
    updatedAt: string;
  };
  id: 3;
}

export type ErrorData = {
  data?: null;
  error?: {
    details: any;
    message: string;
    name: string;
    status: number;
  };
};

export const postPlayer = (props: TProps) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/players/`,
      { data: props },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    .then(({ data: { data } }: { data: { data: SuccessData } }) => ({
      ...data,
      status: 'success',
    }))
    .catch((err) => {
      if (err?.response?.data?.error?.name === 'ValidationError')
        return {
          ...err.response.data,
          status: 'usedEmail',
        };
      return { status: 'error' };
    });
};

import st from './Checkbox.module.scss';

interface CheckboxProps {
  id: string;
  label: string;
  error?: boolean;
  rest?: any;
}

export const Checkbox = ({ id, label, error, rest }: CheckboxProps) => (
  <div className={st.wrapper}>
    <input className={st.checkbox} data-error={error} type="checkbox" id={id} {...rest} />
    <label htmlFor={id}>
      <span />
      {label}
    </label>
  </div>
);

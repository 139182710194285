import { Layer, Rect, Stage } from 'react-konva';
import useImage from 'use-image';
import girlImage from 'shared/assets/desktop/character/girl.png';
import logoImage from 'shared/assets/desktop/misc/logo.png';
import st from './BackGround.module.scss';
import { useSelector } from 'react-redux';

const canvas = {
  h: 694,
  w: 1290,
};
const girl = {
  h: 369,
  w: 267,
};
const logo = {
  h: 107,
  w: 160,
};

const BackGround = ({ isRotated = false }: { isRotated?: boolean }) => {
  const scale = useSelector((state) => (state as any).gameProperties.scale);

  return (
    <div className={`${st.wrapper} ${isRotated ? st.wrapper_rotated : ''}`}>
      <Stage width={canvas.w * scale} height={canvas.h * scale} scaleX={scale} scaleY={scale}>
        <Layer>
          <Rect
            fillPatternImage={useImage(girlImage)[0]}
            x={Math.floor(canvas.w * 0.5 - girl.w * 0.5)}
            y={canvas.h - girl.h}
            width={girl.w}
            height={girl.h}
          />
          <Rect
            fillPatternImage={useImage(logoImage)[0]}
            x={30}
            y={30}
            width={logo.w}
            height={logo.h}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default BackGround;

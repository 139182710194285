import FinishScreen from 'app/screens/FinishScreen';
import IntermediateScreen from 'app/screens/IntermediateScreen';
import PortraitScreen from 'app/screens/PortraitScreen';
import StartupScreen from 'app/screens/StartupScreen';
import { defineScale } from 'app/slices/gamePropertiesSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useOrientation } from '@uidotdev/usehooks';
import Game from 'shared/game';

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
    </Routes>
  );
};

const screens = {
  game: <Game />,
  finish: <FinishScreen />,
  intermediate: <IntermediateScreen />,
  start: <StartupScreen />,
};

function Main() {
  const [isLanscape, setIsLanscape] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const currentScreen: 'game' | 'start' | 'intermediate' | 'finish' = useSelector(
    (state) => (state as any).game.screen
  );
  const dispatch = useDispatch();
  const orientation = useOrientation();

  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    if (isMounted) {
      const w = window.screen.width;
      const h = window.screen.height;
      if (orientation.type === 'portrait-primary') {
        const ar = Math.min(w / 694, h / 1290);
        ar && ar < 1 && dispatch(defineScale(ar * 0.85));
      } else {
        const ar = Math.min(h / 694, w / 1290);
        ar && ar < 1 && dispatch(defineScale(ar * 0.85));
      }
    }
  }, [isMounted]);

  useEffect(() => {
    if (orientation.type === 'portrait-primary') {
      setIsLanscape(false);
    } else {
      setIsLanscape(true);
    }
  }, [orientation]);

  if (!isMounted) return null;

  return !isLanscape ? <PortraitScreen /> : screens[currentScreen];
}

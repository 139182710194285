import { FieldError } from 'react-hook-form';
import st from './InputText.module.scss';

interface InputTextProps {
  placeholder?: string;
  type?: 'email' | 'text';
  error?: FieldError;
  rest?: any;
}

const errorMessages = {
  required: 'Обязательное поле',
  maxLength: 'Превышает максимальную длину',
  pattern: 'Неверный email',
};

export const InputText = ({ placeholder, type = 'text', error, rest }: InputTextProps) => (
  <div>
    <input
      className={st.input}
      placeholder={placeholder}
      type={type}
      data-error={!!error}
      {...rest}
    />
    {error && error.type ? (
      <p>{errorMessages[error.type as 'required' | 'maxLength' | 'pattern']}</p>
    ) : null}
  </div>
);

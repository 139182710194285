import { Button } from 'shared/ui/Button/Button';
import st from '../../screens.module.scss';
import { TPages } from '..';
import { getReward } from 'shared/constants/rewards';

type Props = {
  points: number;
  setPage: React.Dispatch<React.SetStateAction<TPages>>;
};

const NotificationPage = ({ points, setPage }: Props) => {
  const header =
    points > 100 ? (
      <>
        <h2 className={st.header}>{points} баллов</h2>
        <p className={st.text}>А вы разбираетесь в спортивных вещах!</p>
        <p className={st.text_big}>Держите приз: {getReward(points)}.</p>
      </>
    ) : (
      <>
        <h2 className={st.header}>Вы собрали меньше 100 баллов</h2>
        <p className={st.text}>А Ксюша так и не смогла собраться в зал по-спортивному.</p>
        <p className={st.text}>
          <b>По секрету:</b> Обновите страницу, чтобы попробовать снова. Или заберите
          гарантированный приз.
        </p>
      </>
    );

  return (
    <div className={st.wrapper}>
      {header}
      <div className={st.buttons_wrapper}>
        <Button label={'Забрать подарок'} onClick={() => setPage('form')} />
      </div>
    </div>
  );
};

export default NotificationPage;
